
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "黑名單",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "CreatedTime",
          title: "鎖定時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Member.Number",
          title: "會員編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Member.Person.Name",
          title: "會員姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "StartTime",
          title: "開始時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "EndTime",
          title: "結束時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Description",
          title: "事由",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      promises: {
        query: model
          ? (params) => {
            const now = new Date();
            params.condition!.and("IsReleaseNow", Operator.Equal);
            return model.dispatch('ban/query', params)
          }
          : undefined,
        queryAll: model ? () => model.dispatch('ban/query') : undefined,
        save: model
          ? (params) => model.dispatch('ban/save', params)
          : undefined
      },
      modalConfig: { width: "60%", height: "auto", showFooter: true },
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        { field: "MemberId", title: "會員", span: 24, slots: { default: "column-member-id" } },
        { field: "StartTime", title: "開始時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入時間" } } },
        { field: "EndTime", title: "結束時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入時間" } } },
        {
          field: "Description",
          title: "事由",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: {
              placeholder: "請輸入事由"
            },
          },
        }
      ],
      rules: {
        MemberId: [{ required: true }]
      },
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'value',
      placeholder: '選擇會員',
      textField: 'label',
      valueField: 'value',
      columns: [
        { field: 'label', title: '姓名', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => new Promise(resolve => {
          model!.dispatch("member/find", value).then(res => resolve({ label: res.Person.Name, value: res.Id }));
        }),
        query: (params) => model!.dispatch("member/options", params) // eslint-disable-line
      },
    }

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.StartTime = new Date();
      row.EndTime = new Date();
      row.EndTime.setMonth(row.EndTime.getMonth() + 1);
      callback();
    }
  }
});
